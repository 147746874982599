import React from "react"
import { Box } from "theme-ui"
import { saturate } from "@theme-ui/color"

const  IconTerritory = ( props ) => {

  const height = props.height ? `${props.height}px` : `100%`
  const width = props.width ? `${props.width}px` : `100%`
  const fill = props.fill ? `${props.fill}` : `secondary`

  return(
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 300 300"
      height={`${height}`}
      width={`${width}`}
      sx={{
        ".path-territory": {
          fill: saturate(`${fill}`, 0.5),
        }
      }}
    >
      <g className="path-territory">
        <path d="M0.71,301L300,300.137l-87.455-161.454c-2.619-5.135-23.056-44.222-25.404-46.308c-2.348-2.086-3.161,4.173-3.161,4.173 s-2.439-3.852-4.606-8.024s-2.348-4.173-4.064-5.938c-1.716-1.766-6.412-10.592-7.947-10.271 c-1.626,0.321-22.487,36.59-22.487,36.59s-0.722-0.963-1.626-2.086c-0.903-1.123-1.264-0.321-2.348-1.605 c-0.994-1.284-0.994-2.247-2.439-2.247c-0.271,0-0.903,0.641-1.716,1.766c-1.264-1.925-2.619-4.012-3.161-4.012 c-0.903,0-8.399,10.912-9.302,12.517c-0.542,0.963-3.071,4.814-5.328,7.543L0.71,301z M86.357,201.341 c-2.528,2.086-6.141,2.568-7.857,2.728c-0.632-2.889,7.676-11.876,10.205-16.53c2.619-4.975,1.716-3.852,2.619-8.185 c0.903-4.333,11.288-16.208,14.81-23.11c3.161-6.259,13.456-22.467,17.7-25.677c-0.09,1.925-0.09,4.012,0.09,6.259 c0.451,5.617-0.722,8.826-0.903,12.037c-0.181,2.728,4.425-4.333,7.676-9.308v0.161c0.632,1.123,34.588-55.206,36.304-56.81 c1.716-1.605,1.987-1.605,3.251,4.173c1.264,5.778-1.535,8.505-1.716,13.16c-0.181,4.654,1.535,4.654,0.451,10.431 c-1.083,5.777-13.456,27.763-14.54,32.097c-1.083,4.173,5.87-4.654,11.56-13.16c5.689-8.505,6.051-8.826,11.108-12.037 c2.258-1.444,3.071-0.963,3.251,0.482c-0.632,1.284-1.174,2.568-1.445,3.852c-1.174,4.012-0.722,8.024-2.258,12.517 c-1.535,4.493-4.786,9.629-6.141,14.444c-1.355,4.814,1.626,11.715,0.903,13.32s-3.612,5.296-5.238,8.826 c-1.626,3.53-2.98,1.605-7.225,0.482c-4.244-1.123-7.496,6.098-13.095,11.234c-5.599,5.135-17.43,11.234-23.299,11.715 c-5.87,0.482-6.322-2.086-6.141-4.012s4.335-10.912,7.676-15.246c3.341-4.333,7.044-14.122,5.87-16.048 c-1.174-1.925-4.064,4.814-5.87,6.901c-1.806,2.086-3.161-0.482-5.418-1.284c-2.258-0.802-7.947,5.296-12.011,12.037 C102.612,183.53,89.698,198.613,86.357,201.341z"/>
      </g>
    </Box>
  )

}

export default IconTerritory
import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import { Grid, Themed } from "theme-ui"

const LayoutGrid = ( props ) => {

  return (
    <Themed.root>
      <Grid
        as={`div`}
        gap={0}
        sx={{
          backgroundColor: `background`,
          gridTemplateColumns: [  `1fr`,
                                  `1fr`,
                                  `1fr`,
                                  `30vw 1fr`,
                                  `30vw 1fr`,
                                  `30vw 1fr`
                                ],
          gridTemplateRows: [ `4rem auto auto auto`,
                              `4rem auto auto auto`,
                              `4rem auto auto auto`,
                              `4rem 1fr auto`,
                              `4rem 1fr auto`,
                              `4rem 1fr auto`
                            ],
          gridTemplateAreas:[ `"header" "aside" "main" "footer"`, 
                              `"header" "aside" "main" "footer"`, 
                              `"header" "aside" "main" "footer"`, 
                              `"aside header" "aside main" "aside footer"`, 
                              `"aside header" "aside main" "aside footer"`,
                              `"aside header" "aside main" "aside footer"`,
                            ],
          width: `100vw`,
          header: {
            gridArea: `header`,
            pr: [`initial`, `initial`, `initial`, 4, 4, 4],
          },
          ".section-aside": {
            gridArea: `aside`,
            height: [`auto`, `auto`, `auto`, `100vh`, `100vh`, `100vh`],
            position: [`inherit`, `inherit`, `inherit`, `fixed`, `fixed`, `fixed`],
            width: [`100vw`, `100vw`, `100vw`, `30vw`, `30vw`, `30vw`],
          },
          main: {
            gridArea: `main`,
            minHeight: [`calc(100vh - 10rem)`, `calc(100vh - 10rem)`, `calc(100vh - 10rem)`, `calc(100vh - 6rem)`, `calc(100vh - 6rem)`, `calc(100vh - 6rem)`],
            mt: 4,
            px: [3, 3, 3, 4, 4, 4],
            py: [3, 3, 3, 0, 0, 0],
          },
          footer: {
            gridArea: `footer`,
            pr: [`initial`, `initial`, `initial`, 4, 4, 4],
          }
        }}
      >
        <Header />
        {props.children}
        <Footer />
      </Grid>
    </Themed.root>
  )

}

export default LayoutGrid
import React from "react"
import { Box } from "theme-ui"

const IconLocation = () => {

  return(
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 300 300"
      sx={{
        ".fill-color-00": {
          fill: `white`
        },
        ".fill-color-01": {
          fill: `secondary`
        }
      }}
    >
      <g id="icono-location">
        <path d="M150,0.5c-51.606,0-93.438,41.832-93.438,93.438C56.562,187.375,150,299.5,150,299.5s93.438-112.125,93.438-205.562 C243.438,42.332,201.606,0.5,150,0.5z M150,150c-30.965,0-56.062-25.097-56.062-56.062S119.035,37.876,150,37.876 s56.062,25.097,56.062,56.062S180.965,150,150,150z"/>
      </g>
    </Box>
  )

}

export default IconLocation
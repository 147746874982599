import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Box, Flex, Themed } from "theme-ui"
import IconLocation from "./icons/location"

const FilterDepartmentsGuatemala = () => {

  const data = useStaticQuery(
    graphql`
      query TermsDepartmentsGuatemala {
        allCountriesJson(filter: {slug: {eq: "guatemala"}}, sort: {fields: departments___name}) {
          edges {
            node {
              departments {
                name
                slug
              }
            }
          }
        }
        dataJson(slug: {eq: "filter-departments"}) {
          fragment_1
        }
      }
    `
  )

  const allTerms = data.allCountriesJson.edges
  const dataFragments = data.dataJson

  return (
    <Box
      className='filter-deparments'
      sx={{
        h3: {
          color: `guatemala`,
          fontSize: 1,
          fontWeight: `body`,
          mb: 2,
        },
        ul: {
          listStyle: `none`,
          m: 0,
          p: 0,
        },
        li : {
          marginTop: 1,
          marginBottom: 1,
        },
        a: {
          color: `white`,
          borderBottom: `none`,
          fontSize: 1,
          ":hover": {
            borderBottom: `none`,
          },
        },
        ".deparment": {
          alignItems: `center`,
          svg: {
            height: 4,
            fill: `guatemala`,
            mr: 2,
          },
        }
      }}
    >
      <Themed.h3>{dataFragments.fragment_1}</Themed.h3>
      <Box as={`ul`}>
        {allTerms.map(({ node, index }) => (
            node.departments.map( item => (
              <Flex
                as={`li`}
                key={item.slug}
                className={`deparment ${item.slug}`}
              >
                <IconLocation />
                <Link to={`/${item.slug}`}>
                  {item.name}
                </Link>
              </Flex>
            ))
          )
        )}
      </Box>
    </Box>
  )
}

export default FilterDepartmentsGuatemala
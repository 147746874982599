import React from "react"
import { Link } from "gatsby"
import { Box, Flex, Heading, Themed } from "theme-ui"
import { GatsbyImage } from "gatsby-plugin-image"
import { darken } from "@theme-ui/color"

const ActivistGridItem = ( props ) => {

  const bgColor = props.bgcolor ? props.bgcolor : `secondary`

  return (
    <Box
      as={`article`}
      sx={{
        h3: {
          color: `${bgColor}`,
          fontSize: [1, 2],
          fontWeight: `body`,
          mt: 0,
        },
        a: {
          textDecoration: `none`,
        },
        p: {
          color: `${bgColor}`,
          fontSize: 0,
          my: 2,
        },
        svg: {
          height: 5,
          fill: `${bgColor}`,
        },
        ".text-container": {
          backgroundColor: darken(`primary`, 0.04),
          pt: 3,
          px: 3,
          pb: 2,
        },
        ".lawsuitspecific": {
          justifyContent: `space-between`,
          alignItems: `center`,
        }
      }}
    >

      <Link to={`/activista/${props.slug}`}>
      <GatsbyImage
        image={props.illustrationimage}
        alt={props.name}
      />
      <Box className="text-container">
        <Heading as={`h3`}>{props.name}</Heading>
        <Flex className="lawsuitspecific">
          <Themed.p>{props.lawsuitspecific}</Themed.p>
        </Flex>
      </Box>
      </Link>
    </Box>
  )

}

export default ActivistGridItem
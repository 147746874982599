import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Box, Flex, Themed } from "theme-ui"
import IconHumansRights from "./icons/humans-rights"
import IconTerritory from "./icons/territory"
import IconLegalDefence from "./icons/legal-defense"

const FilterLawsuit = ( props ) => {

  const data = useStaticQuery(
    graphql`
      query FilterLawsuit {
        dataJson(slug: {eq: "filter-lawsuit"}) {
          fragment_1
          fragment_2
          fragment_3
          fragment_4
        }
      }
    `
  )

  const color = props.color ? props.color : `secondary`
  const allActivistsFromMain = props.allActivistsFromMain
  const dataFragments = data.dataJson

  /*
   * Array
   * Por cada activista crea un item en el array
   * Cada item del array es un objeto
   * Este objeto tiene tres propiedades: name, slug, specific
   * Tengo que filtrar el array, decirle: recorre el array, lee la propiedad slug de cada item y elimina los items duplicados que tengan el mismo valor de slug. Devuelve el array.
   * Este array pasarlo a componente filter-lawsuit 
  */
  const arrayDeObjetosCadaItemEsUnActivista = allActivistsFromMain.map(single => (
    single.node.lawsuit
  ))

  function uniqItem(data, key) {
    return [
      ...new Map(
        data.map(x =>[key(x), x])
      ).values()
    ]
  }

  const allTermsTaxActivists = uniqItem(arrayDeObjetosCadaItemEsUnActivista, it => it.slug)

  return (
    <Box
      as={`div`}
      className='filter-lawsuit-radio'
      sx={{
        h3: {
          color: `${color}`,
          fontSize: 1,
          fontWeight: `body`,
          mb: 2,
        },
        ul: {
          listStyle: `none`,
          m: 0,
          p: 0,
        },
        li : {
          marginTop: 1,
          marginBottom: 1,
        },
        ".single-term-lawsuit": {
          alignItems: `center`,
          svg: {
            height: 5,
            width: `auto`,
          },
          span: {
            fontFamily: `body`,
            fontSize: 1,
            ml: 2,
            ":hover": {
              color: `tertiary`,
            },
          }
        },
      }}
    >
      <Themed.h3>{dataFragments.fragment_1}</Themed.h3>
      <Box as={`ul`}>
        {allTermsTaxActivists.map(singleTermTax => (
          <Themed.li key={singleTermTax.slug}>
          <Flex
            className={`single-term-lawsuit ${singleTermTax.slug}`}
            type="button"
            aria-label="filter by lawsuit"
            onClick={() => props.setHiddenFromMainComponent(
              allActivistsFromMain.filter( single =>
                single.node.lawsuit.slug === singleTermTax.slug
              )
            )}
          >
            {singleTermTax.slug === `${dataFragments.fragment_2}` &&
              <IconLegalDefence
                fill={`${color}`}
              />
            }
            {singleTermTax.slug === `${dataFragments.fragment_3}` &&
              <IconTerritory
                fill={`${color}`}
              />
            }
            {singleTermTax.slug === `${dataFragments.fragment_4}` &&
              <IconHumansRights
                fill={`${color}`}
              />
            }
            <span>{singleTermTax.name}</span>
          </Flex>
          </Themed.li>
        ))}
      </Box>
    </Box>
  )

}

export default FilterLawsuit